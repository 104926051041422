<template>
  <div class="editHoliday-wrap">
    <NavBar />
    <div class="content">
      <h1>
        <div>
          短信剩余条数：
          <span>{{note.sms_num}}条</span>
        </div>
        <van-button round size="mini" color="#FFEFC5" @click="callPhone('073182297060')">我要充值短信</van-button>
      </h1>
      <div class="line1">
        <h2>短信主题</h2>
        <van-field class="disabled" disabled  v-model="crontab_title" placeholder="请输入短信主题" maxlength="10" />
      </div>
      <div class="line1">
        <h2>发送群体</h2>
        <van-radio-group v-model="send_type" direction="horizontal">
           <van-radio :name="0">发送群体</van-radio>
           <van-radio :name="1">会员卡类型</van-radio>
         </van-radio-group>
        <radioField v-show="send_type===0" v-model="group" :columns="groupList" placement="bottom-end" placeholder="请选择群体" />
        <gTypes v-show="send_type===1" ref="gTypes" v-model="formData.member_card_type" :list="cardTypeList" />
      </div>
      <div class="line2">
        <h2>群发内容</h2>
        <van-field v-model="sms_content" rows="3" autosize type="textarea" maxlength="280" placeholder="请输入短信类容" show-word-limit />
        <p>
          1、单条短信长度（即签名、短信内容和退订方式数字之和）不得 超过280字。
          <br />
          2、超过70字，按60字/条，逐条计费。单个汉字、标点、英文都 算一个字、回车记两个字。
        </p>
      </div>
      <div class="line3">
        <h2>发送时间</h2>
        <van-field v-model="crontab_hour" @click="show = !show" readonly right-icon="arrow" placeholder="请选择"></van-field>
      </div>
      <button class="Lbutton" type="submit" value="取消" @click="$router.go(-1)">取消</button>
      <button class="Notice" type="submit" value="确定" @click="submit">确定</button>
    </div>
    <!-- 选择发送时间 -->
    <van-popup v-model="show" position="bottom"><van-picker title="选择发送时间" show-toolbar :columns="hrList" @confirm="confirmHour" @cancel="show = false" /></van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Toast } from 'vant';
import table from '@/api/table';
import NavBar from '@/components/NavBar';
import radioField from '@/components/radioField';
import gTypes from '@/views/index/components/from/gTypes';
export default{
  name:'editHoliday',
  components: {NavBar,radioField, gTypes},
  data(){
    return{
      crontab_title:'',
      send_type: 0,
      cardTypeList: [],
      formData:{
        group_name:'',
        sms_content:'你好',
        member_card_state: -1,
        member_card_type: '',
        member_consume_num: [],
        member_consume_time: '',
        member_out_day: 0,
        member_pay_money: [],
        member_pay_money_time: '',
        member_sex: 2
      },
      groupList:[],//群组list
      group: '', //选中的发送群体
      group_id:'',
      sms_content:'' ,//短信参数
      show: false,
      hrList: [],
      crontab_hour: '' // 选中小时
    }
  },
  computed:{
    ...mapState(['note'])
  },
  watch: {
    group: {
      handler(newVal) {
        this.group_id = newVal.id;
        this.sms_content=newVal.sms_content
      },
      deep: true
    }
  },
  mounted() {
    let {holiday_name,holiday_hour,id}=this.$route.query
    this.crontab_title=holiday_name
    this.hrList = [];
    for (var i = 0; i < 11; i++) {
      this.hrList.push(i + 9);
    }
    this.memberGroup()
    this.memberCardType()
    // holiday_hour 不为零为编辑
    if(holiday_hour!==0){
      this.crontab_hour=holiday_hour
      table.crontabDetail({id:parseInt(id)}).then(res=>{
        this.sms_content=res.data.sms_content
        this.send_type=res.data.send_type
        this.formData.member_card_type=res.data.member_card_type
      })
    }
  },
  methods:{
    submit(){
      let {id,holiday_date,holiday_hour}=this.$route.query
      if (!this.group_id&&this.send_type===0) {return Toast.fail('请选择发送群体')}
      if (!this.crontab_hour) {return Toast.fail('请选择发送时间')}

      if (this.send_type===1){
        console.log(this.formData)
        this.formData.send_type=this.send_type
        table.memberGroupAdd(this.formData).then(response=>{
          if(response.data.result){
            this.group_id=response.data.id
            let data={}
            // data.send_type=this.send_type
            // data.member_card_type=this.formData.member_card_type
            data.crontab_title=this.crontab_title
            data.sms_content=this.sms_content
            data.crontab_date=holiday_date
            data.group_id=this.group_id
            data.crontab_hour=parseInt(this.crontab_hour)
            // holiday_hour 为零是新增 不为零为编辑
            if(holiday_hour===0){
              table.crontabAdd(data).then(response => {
                if (response.data.result) {
                  this.$router.go(-1); //返回上一层
                }
              })
            }else{
              data.id=id
              table.crontabEdit(data).then(response=>{
                if (response.data.result) {this.$router.go(-1)}
              })
            }
          }
        })
      }else{
        let data={}
        // data.send_type=this.send_type
        // data.member_card_type=this.formData.member_card_type
        data.crontab_title=this.crontab_title
        data.sms_content=this.sms_content
        data.crontab_date=holiday_date
        data.group_id=this.group_id
        data.crontab_hour=parseInt(this.crontab_hour)
        // holiday_hour 为零是新增 不为零为编辑
        if(holiday_hour===0){
          table.crontabAdd(data).then(response => {
            if (response.data.result) {
              this.$router.go(-1); //返回上一层
            }
          })
        }else{
          data.id=id
          table.crontabEdit(data).then(response=>{
            if (response.data.result) {this.$router.go(-1)}
          })
        }
      }
    },
    confirmHour(val){
      this.crontab_hour = val;
      this.show = false
    },
    // 群组列表
    memberGroup() {
      let {group_id}=this.$route.query
      table.memberGroup({}).then(res => {
        this.groupList = res.data.list.map(item => {
          let n=JSON.parse(JSON.stringify(item).replace(/group_name/g, 'text'))
          if(group_id!==0&&group_id===item.id){
            this.group=n
            console.log(this.group)
          }
          return n
        })
        console.log(this.groupList)
      })
    },
    // 获取会员类型
    memberCardType() {
      table.memberCardType().then(response => {
        // response.data.list.unshift({id: 0, card_type_name: "全部"})
        this.cardTypeList=response.data.list
        this.$refs.gTypes.close(response.data.list)
      })
    },
    // 调用手机电话功能
    callPhone(phoneNumber) {
      location.href = 'tel://' + phoneNumber;
    }
  }
}
</script>

<style lang="scss" scoped>
.editHoliday-wrap{
  .van-cell::after {
    border-bottom: none;
  }
  /deep/.van-radio-group{
    margin-bottom: 15px;
    .van-radio{
      margin-right: 15px;
    }
  }
  /deep/.van-picker__toolbar{
    height: 100px;
    border-bottom: 2px solid #F0F0F0;
    div{
      font-size: 32px;
      font-weight: bold;
      color: #333333;
    }
    button{
      font-size: 28px;
      font-weight: 500;
      color: #666;
    }
    .van-picker__confirm{color: #2C6BF8;}
  }
  .content{
    min-height: calc(100vh - 112px);
    padding: 0 30px;
    margin-top: 20px;
    background: #fff;
    .van-field {
      line-height: 70px;
      padding: 0 23px;
      background: #ffffff;
      border: 2px solid #e9e9e9;
      border-radius: 10px;
      /deep/.van-icon {
        display: flex;
        align-items: center;
        img {
          width: 33px;
          height: 31px;
        }
      }
    }
    h2 {
      font-size: 26px;
      font-weight: bold;
      color: #333333;
    }
    p {
      margin-top: 27px;
      font-weight: 500;
      font-size: 24px;
      color: #999999;
    }
    h1 {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #e9e9e9;
      > div {
        font-size: 26px;
        font-weight: bold;
        color: #333;
        span {
          color: #ff6600;
        }
      }
      button {
        color: #d05f00 !important;
        font-size: 22px;
        font-weight: 500;
        height: 42px;
        padding: 0 25px;
      }
    }
    .line1{
      margin-top: 37px;
      h2{margin-bottom: 30px;}
      .disabled{
        background: #EEE;
        /deep/input{background: #EEE;color:#666666}
      }
    }
    .line2 {
      margin-top: 50px;
      .van-field {
        padding-top: 20px;
        font-size: 26px;
        line-height: 32px;
      }
      h2 {
        margin-bottom: 27px;
      }
      /deep/.van-field__word-limit {
        color: #2c6bf8;
      }
    }
    .line3{
      h2{
        margin: 40px 0 30px;
      }
    }
    .Lbutton {
      border: 1px solid #2c6bf8;
      width: 300px;
      height: 80px;
      border-radius: 40px;
      margin-top: 80px;
      margin-bottom: 50px;
      color: #2c6bf8;
      margin-left: 50px;
      font-size: 30px;
      background: #f4f8ff;
    }
    .Notice {
      border: 1px solid #2c6bf8;
      width: 300px;
      height: 80px;
      border-radius: 40px;
      margin-top: 80px;
      margin-bottom: 50px;
      color: #ffffff;
      font-size: 30px;
      margin-left: 40px;
      background-color: #2c6bf8;
    }
  }
}
</style>
